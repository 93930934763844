<template>
  <div>
    <h1>{{ __('Page Not Found') }}</h1>

    <router-link :to="{ name: 'home' }">
      {{ __('Take Me Home') }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'NotFoundPage'
}
</script>
